<template>
  <div class="selling-point-container layout-padding">
    <PageTitle
      class="my-8"
      :title="content.eventsCalendar"
      :childOne="content.eventsCalendar"
      routeOne="/events"
    />
    <div class="grid grid-cols-2 gap-10 my-8" v-if="loading">
      <div class="col-span-2 md:col-span-1" v-for="index in 12" :key="index">
        <EventCardSkeleton />
      </div>
    </div>
    <div class="grid grid-cols-2 gap-10 my-8" v-else>
      <div
        class="col-span-2 md:col-span-1"
        v-for="event in events"
        :key="event.id"
      >
        <EventCard :event="event" />
      </div>
    </div>
  </div>
</template>

<script>
import EventCard from "/src/components/ui/Events/EventCard.vue";
import EventCardSkeleton from "/src/components/ui/Skeletons/EventCardSkeleton.vue";

export default {
  components: { EventCard, EventCardSkeleton },
  inject: ["content"],
  data() {
    return {
      loading: true,
    };
  },
  async mounted() {
    await this.$store.dispatch("events/events");
    this.loading = false;
  },
  computed: {
    events() {
      return this.$store.getters["events/events"];
    },
  },
};
</script>