<template>
  <div class="event-card flex ">
    <div class="date-section">
      <div class="day lora-bold">{{ day }}</div>
      <div class="year">{{ month }}</div>
      <div class="year">{{ year }}</div>
    </div>
    <div class="content-section">
      <div class="event-title lora-bold">{{ event.title }}</div>
      <div class="event-description mt-3">
        {{ event.description }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["event"],
  computed: {
    year() {
      return this.event.date.split(",")[0];
    },
    month() {
      return this.event.date.split(",")[1];
    },
    day() {
      return this.event.date.split(",")[2];
    },
  },
};
</script>