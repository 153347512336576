<template>
  <div class="event-card-skeleton flex flex-col smd:flex-row">
    <div class="date-section"></div>
    <div class="content-section w-full p-5">
      <div class="title lora-bold"></div>
      <div class="description mt-3 mb-2"></div>
      <div class="description mt-3 mb-2"></div>
    </div>
  </div>
</template>

